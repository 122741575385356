import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"
import {Nodata,  NodataContainer, NodataTitle, NodataSubTitle, NodataDesc} from "../components/Nodata"
import {ArrowRightIcon} from "../components/Icons"
import { Section } from "../components/Section"
import DefaultButton from "../components/Button/DefaultButton"

const NotFoundPage = ({ location }) => (
  <Layout>
    <Seo title="404: Not Found" />
    <Section pt="120px" pb="120px" xpt="60px" xpb="60px" mpt="40px" mpb="40px" bgColor="#fff">
      <div className="container">
        <Nodata>
          <NodataContainer>
            <NodataTitle>404 ERROR</NodataTitle>
            <NodataDesc>
              <NodataSubTitle className="h3">Page Not Found</NodataSubTitle>
                <p>Sorry, we can’t find the page you are looking for click here to go back to the home page.</p>
                <Link to="/"><DefaultButton iconAfter={<ArrowRightIcon />} text="Go To Home" animate="ripple-black" /></Link>
            </NodataDesc>
          </NodataContainer>
        </Nodata>
      </div>
    </Section>
  </Layout>
)

export default NotFoundPage
